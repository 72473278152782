import React, { useEffect, useMemo, useState } from 'react';
import { keyBy } from 'lodash';

import Wall from './Wall';

import useRandomBgColor from '../../hooks/useRandomBgColor';
import { replaceShowSlugWithObj } from '../../utils/showUtils';

interface WallWrapperProps {
  data: {
    liveRecording?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveRecording;
            slug: string;
          };
        };
      }[];
    };
    liveVideo?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveVideo;
            slug: string;
          };
        };
      }[];
    };
    photo?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Photo;
            slug: string;
          };
        };
      }[];
    };
    polaroid?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Polaroid;
            slug: string;
          };
        };
      }[];
    };
    poster?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Poster;
            slug: string;
          };
        };
      }[];
    };
    text?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Text;
            slug: string;
          };
        };
      }[];
    };
    show: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Show;
            slug: string;
          };
        };
      }[];
    };
  };
}

const WallWrapper: React.FC<WallWrapperProps> = function (props) {
  const {
    data: { liveRecording, liveVideo, photo, polaroid, poster, text, show },
  } = props;

  const showsKeyed = useMemo(() => {
    const shows = show.edges.map(edge => edge.node.childMdx);
    return keyBy(shows, 'slug');
  }, [show]);

  useEffect(() => {
    liveRecording.edges = replaceShowSlugWithObj(
      liveRecording.edges,
      showsKeyed
    );
    liveVideo.edges = replaceShowSlugWithObj(liveVideo.edges, showsKeyed);
    photo.edges = replaceShowSlugWithObj(photo.edges, showsKeyed);
    polaroid.edges = replaceShowSlugWithObj(polaroid.edges, showsKeyed);
    poster.edges = replaceShowSlugWithObj(poster.edges, showsKeyed);
    text.edges = replaceShowSlugWithObj(text.edges, showsKeyed);
  }, [liveRecording, liveVideo, photo, polaroid, poster, showsKeyed, text]);

  const [content, setContent] = useState<Common.ContentNode[]>([]);

  useRandomBgColor();

  // Shuffle items and set bg color
  useEffect(() => {
    setContent(
      [
        ...(liveRecording?.edges || []),
        ...(liveVideo?.edges || []),
        ...(photo?.edges || []),
        ...(polaroid?.edges || []),
        ...(poster?.edges || []),
        ...(text?.edges || []),
      ].sort(() => Math.random() - 0.5)
    );
  }, []);

  return <Wall content={content} />;
};

export default WallWrapper;
