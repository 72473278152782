import { Dispatch, SetStateAction, useEffect } from 'react';

export default function useContentFromUrl(
  content: Common.ContentNode[],
  setCurrContent: Dispatch<SetStateAction<Common.ContentChildMdx | null>>
): void {
  useEffect(() => {
    const currContentSlug = new URLSearchParams(window.location.search).get(
      'content'
    );

    if (currContentSlug) {
      const item = content.find(edge => {
        return edge.node.childMdx.slug == currContentSlug;
      });

      if (item) {
        setCurrContent(item.node.childMdx);
      }
    }
  }, [content, setCurrContent]);
}
