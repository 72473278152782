type Show = {
  frontmatter: {
    city: string;
    country: string;
    date: string;
    title: string;
    venue: string;
  };
  id: string;
  slug: string;
};

type Edge = {
  node: {
    childMdx: {
      frontmatter: object;
      id: string;
    };
  };
};

interface ShowsObj {
  [key: string]: Show;
}

interface ShowQueryData {
  node: {
    childMdx: Show;
  };
}

export function showsArrayToObj(showsArr: ShowQueryData[]): ShowsObj {
  const initial: ShowsObj = {};
  return showsArr.reduce((obj: ShowsObj, show: ShowQueryData) => {
    return {
      ...obj,
      [show.node.childMdx.slug]: show.node.childMdx,
    };
  }, initial);
}

export function replaceShowSlugWithObj(edges: Edge[], showsKeyed): Edge[] {
  if (edges.length > 0) {
    if (!(typeof edges[0].node.childMdx.frontmatter.show === 'string')) {
      return edges;
    }
  }

  return edges
    .map(({ node: { childMdx } }) => {
      const normalisedSlug = childMdx.frontmatter.show
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      const show = showsKeyed[normalisedSlug];

      if (show == null) {
        console.warn(
          `Could not find show data for '${childMdx.frontmatter.title}'`
        );
        return {} as Edge;
      }

      return {
        node: {
          childMdx: {
            ...childMdx,
            frontmatter: {
              ...childMdx.frontmatter,
              show: {
                ...showsKeyed[normalisedSlug].frontmatter,
                slug: normalisedSlug,
              },
            },
          },
        },
      };
    })
    .filter(edge => 'node' in edge);
}
