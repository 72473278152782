import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Loading } from '../../assets/images/loading.svg';

type StyledLoadingWrapperProps = {
  $loading: boolean;
};

const StyledLoadingWrapper = styled.div<StyledLoadingWrapperProps>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  will-change: opacity;
  pointer-events: none;
  ${props => !props.$loading && 'animation: fadeout 2s;'};
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  @keyframes fadeout {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      display: none;
    }
  }
`;

const StyledLoading = styled(Loading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1.2rem;
`;

interface LoaderProps {
  loading: boolean;
}

const Loader: React.FC<LoaderProps> = props => {
  const { loading } = props;

  return (
    <StyledLoadingWrapper $loading={loading}>
      <StyledLoading />
    </StyledLoadingWrapper>
  );
};

export default Loader;
